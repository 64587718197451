export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Proyectos',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Activos',
        route: 'active-projects',
      },
      {
        title: 'Hold',
        route: 'hold-projects',
      },
      {
        title: 'Finalizados',
        route: 'finalized-projects',
      },
    ],
  },
  {
    title: 'Equipo',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Equipo',
        route: 'second-page',
      },
      {
        title: 'Posiciones',
        route: 'positions',
      },
    ],
  },
]
